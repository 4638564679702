<template>
  <div class="home-wrapper">
    <img
      class="monogram"
      src="@/assets/images/jm_monogram.svg"
      alt="Logo"
    >
  </div><!-- .home-wrapper -->
</template>

<script>
  export default {
    name: "Home"
  }
</script>

<style lang="scss" scoped>
.home-wrapper {
  .monogram {
    width: 400px;
  }
}
</style>